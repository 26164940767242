import { ref } from "@vue/reactivity";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import { languages } from "../../service/language";

export function useLocaleSwitcher() {
  const { t, locale } = useI18n();
  const route = useRoute();
  const router = useRouter();
  const availableLocales = languages(t);
  const selectedLocale = ref(route.params.lang);

  const onChange = async (aLocale) => {
    locale.value = aLocale;
    await router.push({ params: { lang: locale.value } });
    availableLocales.value = languages(t);
  };

  return {
    availableLocales,
    selectedLocale,
    onChange,
  };
}
