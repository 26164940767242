import { PrimeIcons } from "primevue/api";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import { Role } from "../../models/Role";
import { GET_PROFILE } from "../../store/modules/auth/auth.getters.type";

export function useMenuTree() {
  const store = useStore();
  const profile = store.getters[GET_PROFILE];

  const { t } = useI18n();
  // Basic navigation
  const basic = [
    {
      label: t("section.dashboard"),
      icon: PrimeIcons.HOME,
      to: { name: "dashboard" },
    },
    {
      label: t("section.processes"),
      icon: PrimeIcons.VIDEO,
      items: [
        {
          label: t("section.processes_new"),
          icon: PrimeIcons.PLUS_CIRCLE,
          to: { name: "upload-video" },
        },
        {
          label: t("section.processes_status"),
          icon: PrimeIcons.CLONE,
          to: { name: "processes" },
        },
      ],
    },
  ];

  const help = {
    label: t("section.help"),
    icon: PrimeIcons.QUESTION_CIRCLE,
    to: { name: "help" },
  };

  // Navigation menu for user - ROLE_CLIENT
  const user = [...basic, help];

  // Navigation menu for admins - ROLE_ACCOUNT
  const admin = [
    ...basic,
    {
      label: t("section.users"),
      icon: PrimeIcons.USER_EDIT,
      to: { name: "users" },
    },
    {
      label: t("section.statement"),
      icon: PrimeIcons.BOOK,
      to: { name: "statement" },
    },
    {
      label: t("section.contact_us"),
      icon: PrimeIcons.ENVELOPE,
      to: { name: "contact-us" },
    },
    help,
  ];

  return {
    menu: profile.hasRole(Role.admin) ? admin : user,
  };
}
