<template>
  <div class="layout-footer">
    <img
      src="/assets/layout/images/bioobserver_icon.png"
      alt="Herta Security logo"
      width="25"
    />
    <span class="footer-text" style="margin-left: 15px">BioObserver</span>
  </div>
</template>

<script>
export default {
  name: "ThePanelFooter",
};
</script>

<style scoped></style>
