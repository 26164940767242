<template>
  <div class="layout-topbar">
    <button class="p-link layout-menu-button" @click="onMenuToggle">
      <span class="pi pi-bars" />
    </button>
    <div class="layout-topbar-icons">
      {{ $t("top_bar.welcome") }} <strong>{{ profile.name }}</strong>
      <button class="p-link" @click="toProfile">
        <span class="layout-topbar-item-text">User</span>
        <span class="layout-topbar-icon pi pi-user" />
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "ThePanelTopBar",
  emits: ["menu-toggle"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const profile = computed(() => store.state.auth.profile ?? "");

    function onMenuToggle(event) {
      emit("menu-toggle", event);
    }

    function toProfile() {
      router.push({ name: "profile" });
    }

    return {
      profile,
      onMenuToggle,
      toProfile,
    };
  },
};
</script>
