<template>
  <div :class="containerClass" @click="onWrapperClick">
    <the-panel-top-bar @menu-toggle="onMenuToggle" />
    <transition name="layout-sidebar">
      <div
        v-show="isSidebarVisible()"
        class="layout-sidebar layout-sidebar-dark"
        @click="onSidebarClick"
      >
        <div class="layout-logo">
          <router-link :to="{ name: 'dashboard' }">
            <img
              alt="Herta security"
              src="/assets/layout/images/logo-white.png"
            />
          </router-link>
        </div>
        <the-panel-profile />
        <the-panel-menu :model="menu" @menuitem-click="onMenuItemClick" />
      </div>
    </transition>
    <div class="layout-main">
      <panel />
    </div>
    <the-panel-footer />
  </div>
</template>

<script>
import ThePanelFooter from "../../components/layout/ThePanelFooter";
import ThePanelMenu from "../../components/layout/ThePanelMenu";
import ThePanelProfile from "../../components/layout/ThePanelProfile";
import ThePanelTopBar from "../../components/layout/ThePanelTopBar";
import { useMenuTree } from "../../components/layout/useMenuTree";
import Panel from "./Panel";

export default {
  name: "Layout",
  components: {
    Panel,
    ThePanelMenu,
    ThePanelProfile,
    ThePanelTopBar,
    ThePanelFooter,
  },
  data() {
    return {
      layoutMode: "static",
      layoutColorMode: "dark",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [],
    };
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$appState.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.ripple === false,
        },
      ];
    },
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  created() {
    const { menu } = useMenuTree();
    this.menu = menu;
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += ` ${className}`;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(`(^|\\b)${className.split(" ").join("|")}(\\b|$)`, "gi"),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
  },
};
</script>
